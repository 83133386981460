import React from 'react';
import ReactDOM from 'react-dom';
import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {
  AuthProvider,
  AuthManagers,
  Authenticated,
  Gatekeeper,
  AuthReducer,
  // @ts-ignore
} from 'kubra-lib-react-auth/dist';

import { auth } from '../../../config';
import appPermissions from '../../../config/permissions';
import { getRootReducerWith } from '../../../reducers';
import createApiMiddleware from '../../../middleware/api';


const history = createBrowserHistory();

const authManager = new AuthManagers.Auth0(auth);

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  getRootReducerWith({
    auth: AuthReducer,
  }),
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
      createApiMiddleware(authManager.authGuard),
    ),
  ),
);

export default (appComponent: any, containerId = 'root'): void => {
  ReactDOM.render(
    <Provider store={store}>
      <AuthProvider
        authManagerInstance={authManager}
        store={store}
      >
        <Authenticated>
          <Gatekeeper
            conditions={appPermissions}
            pass={(): JSX.Element => (
              <Router history={history}>
                { React.createElement(appComponent) }
              </Router>
            )}
            fail={(): JSX.Element => (
              <h2>{'You don\'t have permissions to access this application'}</h2>
            )}
          />
        </Authenticated>
      </AuthProvider>
    </Provider>,
    document.getElementById(containerId),
  );
};
