import _ from 'lodash';
import {
  GET_TENANTS_SUCCESS,
} from '../../actions/tenants';

const initialTenantsState = {
  byId: {},
  allIds: [],
};

export const tenants = (
  state = initialTenantsState,
  action: { type: string },
): typeof initialTenantsState => {
  switch (action.type) {
    case GET_TENANTS_SUCCESS:
      return Object.assign({}, {
        ...state,
        byId: {
          ..._.get(action, ['response', 'entities', 'tenants'], {}),
        },
        allIds: _.get(action, ['response', 'result', 'tenants'], []),
      });
    default:
      return state;
  }
};

export default tenants;
