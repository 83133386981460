import React, { useEffect, useContext } from 'react';
import updateUrlParams from 'lib/updateUrlParams';
import ThemeContext from 'modules/Presales/context/ThemeContext';
import MainWrapper from '../wrappers/MainWrapper';
import SubpageSimpleWrapper from '../wrappers/SubpageSimpleWrapper';

const navItems = [
  {
    label: 'Manage My Profile',
    nav: 'manageProfile',
  },
  {
    label: 'Update Mailing Address',
    nav: 'updateMailingAddress',
  },
  {
    label: 'Accounts Management',
    nav: 'accountsManagement',
  },
];

const PrePay = (): JSX.Element => {
  const root = 'https://kubra.io/prepayconsumer/?instanceId=67688c2b-e149-4b1e-b260-52ba3afc6a79&articleHash=TungstenEnergy::Account::1103';

  // Grab theme and update the url params to include ?[customer]
  const theme = useContext(ThemeContext);
  useEffect((): void => {
    updateUrlParams('prepay', theme.param);
    window.scrollTo(0, 0);
  });

  return (
    <div id="prepay">
      <MainWrapper>
        <SubpageSimpleWrapper
          navItems={navItems}
          activeItem="test"
          header="PRE-PAY"
          subtext="Load and Go Payments"
        >
          <div id="center-panel" className="container-vertical">
            <div>
              <iframe id="prepay" frameBorder="0" scrolling="yes" src={root}/>
            </div>
          </div>
        </SubpageSimpleWrapper>
      </MainWrapper>
    </div>
  );
};

export default PrePay;
