import React from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import { socialLinks, pageLinks } from 'constants/headerLinks';

const pageLinksLocation2 = _.filter(pageLinks, { linkLocation: 2 });

const PageHeader = (): JSX.Element => (
  <header className="container-flex" id="page-header">
    <div className="container-flex container-vertical-center-items item-flex-1">
      {socialLinks.map((socialLink, index): JSX.Element => (
          <div className="page-header-link" key={index}>
            <img src={socialLink.imgSrc} alt={socialLink.imgAlt} />
          </div>
      ))}
    </div>
    <div className="container-flex container-vertical-center-items container-right-align-items item-flex-1">
      {pageLinksLocation2.map((pageLink, index): JSX.Element => (
        <div className="page-header-link" key={index}>
          {pageLink.external && (
            <a href={pageLink.linkHref} key={index} target="_blank" rel="noopener noreferrer">
              {pageLink.linkLabel}
            </a>
          )}
          {!pageLink.external && (
            <NavLink to={pageLink.linkHref}>
              {pageLink.linkLabel}
            </NavLink>
          )}
        </div>
      ))}

    </div>
  </header>
);

export default PageHeader;
