import React from 'react';
import AbcLogo from 'assets/logos/abc-logo.png';
import CSULogo from 'assets/logos/csu.png';
import EvsLogo from 'assets/logos/eversource-logo.png';
import PsegliLogo from 'assets/logos/psegli-logo.png';
import NveLogo from 'assets/logos/nve-logo.png';
import RepublicLogo from 'assets/logos/republic-logo.png';
import TecoLogo from 'assets/logos/teco-logo.png';
import UnitilLogo from 'assets/logos/unitil-logo.png';
import WashingtonGasLogo from 'assets/logos/washingtongas-logo.png';
import DLCLogo from 'assets/logos/dlc-logo.png';

export interface Theme {
  param: string;
  title: string;
  logo: string;
  colorPrime?: string;
  colorSecond?: string;
}

interface ThemesObj {
  [key: string]: Theme;
}

export const themes: ThemesObj = {
  abc: {
    param: '?abc',
    title: 'ABC Energy ',
    logo: AbcLogo,
  },
  csu: {
    param: '?csu',
    title: 'Colorado Springs Utilities',
    logo: CSULogo,
    colorPrime: '#3b3d96',
    colorSecond: '#34735c',
  },
  eversource: {
    param: '?eversource',
    logo: EvsLogo,
    title: 'EVS',
    colorPrime: '#1BA9DF',
    colorSecond: '#AACFDD',
  },
  psegli: {
    param: '?psegli',
    logo: PsegliLogo,
    title: 'PSEG Long Island',
    colorPrime: '#d25b19',
    colorSecond: '#FFD200',
  },
  nve: {
    param: '?nve',
    logo: NveLogo,
    title: 'NVE',
    colorPrime: '#267f63',
    colorSecond: '#637881',
  },
  republic: {
    param: '?republic',
    title: 'Republic',
    logo: RepublicLogo,
    colorPrime: '#d01947',
    colorSecond: '#296182',
  },
  teco: {
    param: '?teco',
    title: 'TECO',
    logo: TecoLogo,
    colorPrime: '#205699',
    colorSecond: '#FFD200',
  },
  unitil: {
    param: '?unitil',
    title: 'Unitil',
    logo: UnitilLogo,
    colorPrime: '#ff8900',
    colorSecond: '#25327b',
  },
  washingtongas: {
    param: '?washingtongas',
    title: 'Washington Gas',
    logo: WashingtonGasLogo,
    colorPrime: '#245fa5',
    colorSecond: '#000',
  },
  dlc: {
    param: '?dlc',
    title: 'Duquesne Light Co.',
    logo: DLCLogo,
    colorPrime: '#F6921D',
    colorSecond: '#000000',
  },
};

export const ThemeContext = React.createContext(
  themes.abc, // default value
);

export default ThemeContext;
