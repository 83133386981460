import React, { useRef, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import routeConstants from 'config/routeConstants';
import Google from 'assets/google-plus.svg';
import User from 'assets/user.svg';
import Password from 'assets/password.svg';
import updateUrlParams from 'lib/updateUrlParams';
import ThemeContext from '../context/ThemeContext';

const Login = (): JSX.Element => {
  const formRef = useRef(document.createElement('form'));
  const emailRef = useRef(document.createElement('input'));
  const passwordRef = useRef(document.createElement('input'));
  const handleClick = (e: any): any => {
    if (!formRef.current.reportValidity()) {
      return e.preventDefault();
    }
    return null;
  };

  // Grab theme and update the url params to include ?[customer]
  const theme = useContext(ThemeContext);
  useEffect((): void => {
    updateUrlParams('login', theme.param);
    window.scrollTo(0, 0);
  });

  return (
    <div id="login-page">
      <section id="main-body">
        <div id="wrap-content" className="container-flex container-vertical container-vertical-center-items">
          <div id="login-header-logo">
            <ThemeContext.Consumer>
              {(value): JSX.Element => <img src={value.logo} alt="logo" />}
            </ThemeContext.Consumer>
          </div>
          <div id="log-in-with-google" className="container-flex container-vertical-center-items" role="button" tabIndex={1}>
            <div id="google-logo" className="container-flex container-vertical-center-items container-center-align-items">
              <img src={Google} alt="google" />
            </div>
            <div id="google-text">
              Log in with Google
            </div>
          </div>
          <div id="or">
            or
          </div>
          <form id="login-form" className="container-flex container-vertical container-vertical-center-items" ref={formRef}>
            <div className="input-container">
              <input
                ref={emailRef}
                id="email-input"
                type="email"
                placeholder="Email"
                name="email"
                required
              />
              <span className="input-icon"><img src={User} alt="user" /></span>
            </div>
            <div className="input-container">
              <input
                ref={passwordRef}
                id="password-input"
                type="password"
                placeholder="Password"
                name="password"
                required
              />
              <span className="input-icon"><img src={Password} alt="password" /></span>
            </div>
            <div id="input-error" className={formRef.current.reportValidity() ? 'hidden' : ''}>Username or password is invalid</div>
            <NavLink id="login-button" to={routeConstants.Homepage.fullRoute} onClick={(e): Event => handleClick(e)} style={{ background: theme.colorPrime }}>
              Login
            </NavLink>
          </form>
          <div id="bottom-links" className="container-flex container-vertical-center-items">
            <a href="#">Forgot Password</a>
            <div id="pipe-separator" />
            <a href="#">Create Account</a>
          </div>
          <div id="terms">
            <p>By signing in, you agree to our <a href="#">terms &amp; conditions</a></p>
          </div>
        </div>
        <footer id="login-footer" className="container-flex">
          <div id="blue-box" className="footer-box" style={{ background: theme.colorSecond }} />
          <div id="green-box" className="footer-box" style={{ background: theme.colorPrime }} />
          <div id="grey-box" className="footer-box" />
        </footer>
      </section>
    </div>
  );
};

export default Login;
