import React, { useEffect, useContext } from 'react';
import updateUrlParams from 'lib/updateUrlParams';
import ThemeContext from 'modules/Presales/context/ThemeContext';
import Carousel from '../components/Carousel';
import CentralButtons from '../components/CentralButtons';
import Information from '../components/Information';
import MainWrapper from '../wrappers/MainWrapper';

const Homepage = (): JSX.Element => {
  // Grab theme and update the url params to include ?[customer]
  const theme = useContext(ThemeContext);
  useEffect((): void => {
    updateUrlParams('homepage', theme.param);
    window.scrollTo(0, 0);
  });

  return (
    <div id="homepage">
      <MainWrapper>
        <Carousel />
        <CentralButtons />
        <Information />
      </MainWrapper>
    </div>
  );
};

export default Homepage;
