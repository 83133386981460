import React, { useEffect, useContext, useState } from 'react';
import moment from 'moment';
import { sha256 } from 'js-sha256';
import updateUrlParams from 'lib/updateUrlParams';
import ThemeContext from 'modules/Presales/context/ThemeContext';
import MainWrapper from '../wrappers/MainWrapper';
import SubpageSimpleWrapper from '../wrappers/SubpageSimpleWrapper';
import { DropDown } from '../components/DropDown';

const navItems = [
  {
    label: 'Alert Preferences',
    nav: 'alert-preferences',
  },
];

// Required params for token
// (https://github.com/iFactor/energy-suite-documentation/blob/master/Integration/Notifi_Preference_Center_GUI_Integration.md)
// const principalUser = 'johndoe';
// const accountNum = '100';
// const isoTimeStamp = moment.utc().add(2, 'h').toISOString();
// const hash = sha256.create();
// hash.update(`secretLocal${isoTimeStamp}${accountNum}${principalUser}`);
// const hex = hash.hex().match(/\w{2}/g) || [''];
// const hexMapped = hex.map((a): string => String.fromCharCode(parseInt(a, 16))).join('');

// encode hex output of hash Base64 url-safe (i.e. no / or +)
// const encoded = window.btoa(hexMapped).replace(/\//g, '_').replace(/\+/g, '-');

// const prefsUrlObj = {
//   root: 'https://demo.kubra.io/preferences/?',
//   timestamp: isoTimeStamp,
//   principal: principalUser,
//   token: encoded,
// };

const AlertsManagement = (): JSX.Element => {
  const accountNumbers = [
    { name: '100', value: '100' },
    { name: '101', value: '101' },
    { name: '102', value: '102' },
    { name: '1101', value: '1101' },
    { name: '1102', value: '1102' },
    { name: '1103', value: '1103' },
    { name: '9001', value: '9001' },
    { name: '9002', value: '9002' },
    { name: '9003', value: '9003' },
    { name: '9004', value: '9004' },
    { name: '9005', value: '9005' },
  ];
  const languages = [
    { name: 'english', value: 'en_us' },
    { name: 'spanish', value: 'es_us' },
    { name: 'french', value: 'fr_ca' },
  ];
  const [activeAccount, setActiveAccount] = useState<string>('100');
  const [activeLang, setActiveLang] = useState<string>('en_us');

  // Required params for token
  // (https://github.com/iFactor/energy-suite-documentation/blob/master/Integration/Notifi_Preference_Center_GUI_Integration.md)
  const principalUser = 'johndoe';
  const isoTimeStamp = moment.utc().add(2, 'h').toISOString();
  const hash = sha256.create();
  hash.update(`secretLocal${isoTimeStamp}${activeAccount}${principalUser}`);
  const hex = hash.hex().match(/\w{2}/g) || [''];
  const hexMapped = hex.map((a): string => String.fromCharCode(parseInt(a, 16))).join('');

  // encode hex output of hash Base64 url-safe (i.e. no / or +)
  const encoded = window.btoa(hexMapped).replace(/\//g, '_').replace(/\+/g, '-');
  const root = 'https://demo.kubra.io/preferences/?';
  const prefsSrc = `${root}account=${activeAccount}&timestamp=${isoTimeStamp}&principal=${principalUser}&token=${encoded}&locale=${activeLang}&qualifier=`;

  // Grab theme and update the url params to include ?[customer]
  const theme = useContext(ThemeContext);
  useEffect((): void => {
    updateUrlParams('alerts-management', theme.param);
    window.scrollTo(0, 0);
  });
  const [activeItem] = useState('alert-preferences');

  return (
    <div id="alerts-management">
      <MainWrapper>
        <SubpageSimpleWrapper
          navItems={navItems}
          activeItem={activeItem}
          header="My Alerts and Notifications"
          subtext="Manage contacts, alerts and notification preferences"
        >
          <div id="center-panel" className="container-vertical">
            <div className="account-drop-down">
              <DropDown
                defaultItem={accountNumbers[0]}
                listItems={accountNumbers}
                onChange={setActiveAccount}
              />
              &nbsp;
              <DropDown
                defaultItem={languages[0]}
                listItems={languages}
                onChange={setActiveLang}
              />
            </div>
            <div>
              <iframe id="prefs-gui" src={prefsSrc} />
            </div>
          </div>
        </SubpageSimpleWrapper>
      </MainWrapper>
    </div>
  );
};

export default AlertsManagement;
