import React from 'react';
import SubpageBanner from '../components/SubpageBanner';
import { Item } from '../components/SubpageSidebar';

export interface SubpageSimpleWrapperProps{
  children: React.ReactNode | React.ReactNode[];
  navItems: Item[];
  header: string;
  subtext: string;
  activeItem: string;
}

const SubpageSimpleWrapper = (props: SubpageSimpleWrapperProps): JSX.Element => (
  <div id="subpage-wrapper">
    <SubpageBanner
      header={props.header}
      subtext={props.subtext}
    />
    <div id="subpage-content" className="container-flex">
        {props.children}
    </div>
  </div>
);

export default SubpageSimpleWrapper;
