import React from 'react';
import SubpageBanner from '../components/SubpageBanner';
import { SubpageSidebar, Item } from '../components/SubpageSidebar';

export interface SubpageWrapperProps{
  children: React.ReactNode | React.ReactNode[];
  navItems: Item[];
  header: string;
  subtext: string;
  subpageSidebarOnClick: any;
  activeItem: string;
  fullWidth?: boolean;
}

const SubpageWrapper = (props: SubpageWrapperProps): JSX.Element => (
  <div id="subpage-wrapper">
    <SubpageBanner
      header={props.header}
      subtext={props.subtext}
    />
    <div id="subpage-content" className="container-flex">
      <div id="left-panel">
        <SubpageSidebar
          onClick={props.subpageSidebarOnClick}
          navItems={props.navItems}
          activeItem={props.activeItem}
        />
      </div>
      <div id='right-panel' className={`${props.fullWidth ? 'full-width' : ''} container-flex container-vertical container-vertical-center-items`}>
        {props.children}
      </div>
    </div>
  </div>
);

export default SubpageWrapper;
