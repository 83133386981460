import _ from 'lodash';
import config from './default';

const local = _.cloneDeep(config);

const authConfig = {
  ...local.auth,
  useCookies: true,
  silentCallbackPath: 'http://localhost:3000/silent-callback',
  callbackPath: 'http://localhost:3001/login/callback',
  logoutPath: 'http://localhost:3001/login/logout',
};

local.auth = authConfig;

export default local;
