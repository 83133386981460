import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Menu from 'assets/menu.svg';
import CloseMenu from 'assets/close-menu.svg';
import {
  socialLinksMobile,
  navLinks,
  pageLinks,
} from 'constants/headerLinks';
import routeConstants from 'config/routeConstants';
import FocusContainer from 'modules/utils/FocusContainer';
import ThemeContext from '../context/ThemeContext';

const MobileHeader = (): JSX.Element => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  return (
    <div>
      <header id="mobile-header" className="container-flex container-vertical-center-items">
        <div id="header-logo-mobile" className="container-flex container-vertical-center-items item-flex-2">
          <ThemeContext.Consumer>
            {(value): JSX.Element => (
              <NavLink to={routeConstants.Homepage.fullRoute}>
                <img src={value.logo} alt="logo" />
              </NavLink>
            )}
          </ThemeContext.Consumer>
        </div>
        <div id="mobile-header-links" className="container-flex container-right-align-items item-flex-3">
          {socialLinksMobile.map((socLink, index): JSX.Element => (
            <div className="mobile-header-social-link  item-flex-1" key={index}>
              <img src={socLink.imgSrc} alt={socLink.imgAlt} />
            </div>
          ))}
        </div>
        <div id="mobile-header-menu" className="container-flex container-right-align-items item-flex-1">
          <button id="mobile-header-menu-button" className="closed">
            <img id="mobile-header-menu-button-img" src={menuIsOpen ? CloseMenu : Menu} alt="open menu" onClick={(): void => setMenuIsOpen(!menuIsOpen)} />
          </button>
        </div>
      </header>
      {menuIsOpen && (
        <FocusContainer
          onOutsideClick={(): void => setMenuIsOpen(false)}
          isOpen={menuIsOpen}
        >
          <div id="mobile-header-menu-dropdown" className={`container-flex container-vertical${!menuIsOpen ? ' hidden' : ''}`}>
            <ul id="mobile-menu-dropdown-top-links" className="container-flex container-vertical container-bottom-align-items">
              {navLinks.map((navLink, index): JSX.Element => (
                <li className="mobile-menu-dropdown-top-link" key={index}>
                  <NavLink to={navLink.linkHref}>
                    {navLink.linkLabel}
                  </NavLink>
                </li>
              ))}
            </ul>
            <ul id="mobile-menu-dropdown-bottom-links" className="container-flex  container-vertical container-bottom-align-items">
              {pageLinks.map((pageLink, index): JSX.Element => (
                <li className="mobile-menu-dropdown-bottom-link" key={index}>
                  <NavLink to={pageLink.linkHref}>
                    {pageLink.linkLabel}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </FocusContainer>
      )}
    </div>
  );
};

export default MobileHeader;
