const parentRoute = '';

export default {
  Homepage: {
    name: 'Homepage',
    fullRoute: `${parentRoute}/homepage`,
    reactRouterRoute: 'Homepage',
  },
  AlertsManagement: {
    name: 'AlertsManagement',
    fullRoute: `${parentRoute}/alerts-management`,
    reactRouterRoute: 'AlertsManagement',
  },
  PrePay: {
    name: 'PrePay',
    fullRoute: `${parentRoute}/prepay`,
    reactRouterRoute: 'PrePay',
  },
  Tools: {
    name: 'Tools',
    fullRoute: `${parentRoute}/tools`,
    reactRouterRoute: 'Tools',
  },
  SignIn: {
    name: 'SignIn',
    fullRoute: `${parentRoute}/signin`,
    reactRouterRoute: 'SignIn',
  },
  Login: {
    name: 'Login',
    fullRoute: `${parentRoute}/login`,
    reactRouterRoute: 'Login',
  },
};
