import React, { useEffect, useContext, useState } from 'react';
import updateUrlParams from 'lib/updateUrlParams';
import ThemeContext from 'modules/Presales/context/ThemeContext';
import MainWrapper from '../wrappers/MainWrapper';
import SubpageWrapper from '../wrappers/SubpageWrapper';

const navItems = [
  {
    label: 'Utility OMS',
    nav: 'utilityOMS',
  },
  {
    label: 'Utility CIS',
    nav: 'utilityCIS',
  },
];

const Tools = (): JSX.Element => {
  // Grab theme and update the url params to include ?[customer]
  const theme = useContext(ThemeContext);
  useEffect((): void => {
    updateUrlParams('tools', theme.param);
    window.scrollTo(0, 0);
  });
  const [activeItem, setActiveItem] = useState('utilityOMS');
  const iframeSrc = `https://demo.kubra.io/${activeItem}.php`;

  return (
    <div id="tools">
      <MainWrapper>
        <SubpageWrapper
          navItems={navItems}
          subpageSidebarOnClick={setActiveItem}
          activeItem={activeItem}
          header="Internal Demo Tools"
          subtext="This page provides a means to demonstrate Notifi behavior"
        >
          <div className="iframeHolder">
            <iframe id={ activeItem === 'utilityOMS' ? 'php-form' : 'php-form-long' } frameBorder="0" scrolling="yes" src={iframeSrc} />
          </div>
        </SubpageWrapper>
      </MainWrapper>
    </div>
  );
};

export default Tools;
