import React from 'react';
import Info from 'assets/sign-in-info.png';
// import Energy from 'assets/energy.png';

interface ButtonPrimary {
    onClick?: any;
}

const objDate = new Date();

const lastMonth = `${objDate.toLocaleString('en',
  { day: 'numeric' })}
  ${objDate.toLocaleString('en', { month: 'long' })} 
  ${objDate.toLocaleString('en', { year: 'numeric' })}`;

const SignInInformation = (): JSX.Element => (
  <section id="information" className="container-flex">
  <div id="left-info" className="container-flex container-vertical info-section">
    <div id="top-left-info" className="left-info-section">
      <h2>News &amp; Updates</h2>
      <h6>{lastMonth}</h6>
      <h3>Latest: Power restored to thousands in Los Angelese County</h3>
      <p>Our business mentoring program teaches small-business owners important skills and
        resources to help their companies succeed. Advisers share their experiences with an eye toward promoting
        economic health and stability.</p>
        <button onClick={() => {}}>
            Find Out More
        </button>
    </div>
    <div id="bottom-left-info" className="left-info-section">
      <h6>{lastMonth}</h6>
      <h3>Power utility plans solar projects in Colorado, New Mexico</h3>
      <p>Electric vehicle owners, and those considering the purchase of an electric vehicle,
        stand to benefit from APS&apos;s Energy Efficiency proposal currently before the Arizona Corporation
        Commission.
      </p>
      <button onClick={() => {}}>
        Find Out More
      </button>
    </div>
  </div>
  <div id="right-info" className="container-flex container-vertical container-center-align-items info-section">
    <img src={Info} className="heroImage" alt="Online Services" />
    <p id="first-p">Take advantage of ABC Energy&apos;s online services that make life easier. It&apos;s time to experience
      the ease, convenience, and security of managing your account online.</p>
    <p id="second-p">Questions? Browse our <a href="#">Frequently Asked Questions,</a><br />call <a
        href="#">1.800.766.6616</a> or <a href="#">email your question.</a></p>
    <button id='right-info-button'>Find Out More</button>
  </div>
</section>
);

export default SignInInformation;
