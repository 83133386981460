import React, { useRef, useEffect } from 'react';

export interface FocusContainerProps{
  children: React.ReactNode | React.ReactNode[];
  onOutsideClick: () => void;
  isOpen: boolean;
}

const FocusContainer = (props: FocusContainerProps): JSX.Element => {
  const {
    isOpen,
    children,
    onOutsideClick,
  } = props;
  const focusRef = useRef(document.createElement('div'));
  const handleClickOutside = (event: { target: any }): void => {
    if (!focusRef.current.contains(event.target) && isOpen) {
      onOutsideClick();
    }
  };

  useEffect((): any => {
    document.addEventListener('mousedown', handleClickOutside);
    return (): void => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <div
      className="focus-container"
      ref={focusRef}
    >
      {children}
    </div>
  );
};

export default FocusContainer;
