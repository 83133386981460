import _ from 'lodash';

const middleware = (callback: any, error: boolean = false): any => (result: any): any => {
  callback(result);
  if (error) {
    return Promise.reject(result);
  }
  return Promise.resolve(result);
};

// the key on the action object where the details of the API call are stored
export const CALL_API = 'CALL_API';

const createApiMiddleware = (
  authGuard?: any,
): any => (): any => (next: any): any => (action: any): any => {
  // get the API call details from the action object
  const callAPI = action[CALL_API];
  // if the CALL_API property is not found on the object, it is not an API action, and we can next()
  // to pass it on to the next middleware
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  // destructure the properties of the API action
  const {
    types,
    request,
    misc,
    callback = (): any => { },
    errorCallback = (): any => { },
  } = callAPI;

  // confirm we have the expected number of types
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every((type): boolean => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }


  const actionWith = (data: object): object => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  // destructure the specific action types
  const [
    requestType,
    successType,
    failureType,
  ] = types;

  // next() an action indicating the request has started
  next(actionWith({ type: requestType }));

  if (authGuard) {
    // if we are using authGuard, pass the request, success handlers and failure handlers to
    // the authGuard function
    return authGuard(
      // request fn
      (): void => request(),
      // success handler
      (response: any): void => {
        next(
          actionWith({
            type: successType,
            response: response || {},
            misc,
          }),
        );
        if (callback) middleware(callback(response));
      },
      // error handler
      (error: any): void => {
        next(actionWith({
          type: failureType,
          response: _.get(error, 'response.data', {}),
          misc,
        }));
        if (errorCallback) middleware(errorCallback(error), true);
      },
    );
  }

  // if we are not using authGuard, just make the request and then handle the success/failure
  return request()
    .then((response: any): void => {
      // if we succeed, next() an action with the successType
      next(
        actionWith({
          type: successType,
          response: response || {},
          misc,
        }),
      );
      if (callback) middleware(callback(response));
    })
    // if we throw, next() an action with the failureType
    .catch(
      (error: any): void => {
        next(actionWith({
          type: failureType,
          response: _.get(error, 'response.data', {}),
          misc,
        }));
        if (errorCallback) middleware(errorCallback(error), true);
      },
    );
};

export default createApiMiddleware;
