/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CALL_API } from '../middleware/api';
import iamApi from '../api/IamApi';

export const GET_TENANTS_REQUEST = 'GET_TENANTS_REQUEST';
export const GET_TENANTS_SUCCESS = 'GET_TENANTS_SUCCESS';
export const GET_TENANTS_FAILURE = 'GET_TENANTS_FAILURE';

// Get Tenants
export const getTenants = (tenants: any, callback: any, errorCallback: any): object => ({
  [CALL_API]: {
    types: [
      GET_TENANTS_REQUEST,
      GET_TENANTS_SUCCESS,
      GET_TENANTS_FAILURE,
    ],
    request: (): any => iamApi.tenants.readAll(tenants),
    callback,
    errorCallback,
  },
});

export const loadTenants = (
  tenants: any,
  callback = () => {},
  errorCallback = () => {},
) => (dispatch: (arg0: object) => void) => dispatch(getTenants(tenants, callback, errorCallback));

// Get selected tenant
export const GET_TENANT_REQUEST = 'GET_TENANT_REQUEST';
export const GET_TENANT_SUCCESS = 'GET_TENANT_SUCCESS';
export const GET_TENANT_FAILURE = 'GET_TENANT_FAILURE';

export const getTenant = (tenantId: any, callback: any, errorCallback: any): object => ({
  [CALL_API]: {
    types: [
      GET_TENANT_REQUEST,
      GET_TENANT_SUCCESS,
      GET_TENANT_FAILURE,
    ],
    request: () => iamApi.tenants.read(tenantId),
    callback,
    errorCallback,
  },
});

export const loadTenant = (tenantId: any, callback = () => {},
  errorCallback = () => {}) => (dispatch: (arg0: object) => void) => dispatch(
  getTenant(tenantId, callback, errorCallback),
);
