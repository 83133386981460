import _ from 'lodash';
import axios from 'axios';
import {
  normalize,
} from 'normalizr';

import requestAllPagedData from '../lib/requestAllPagedData';
import getRequestOptions from '../lib/getRequestOptions';
import routes from '../routing';

import {
  tenantSchema,
} from '../schemas';

/**
 * Axios request used to get all tenants
 * @param {*} options
 */
export const readAllRequest = (options: any): any => axios.get(
  routes.tenants.readAll(),
  getRequestOptions(options),
);

/**
 * Get all tenants
*/
export const readAll = (options = {}): any => requestAllPagedData(readAllRequest, options)
  .then((allResponses: any): any => {
    // use reduce to collect the data from all of the requests
    const nextData = _.reduce(allResponses, (dataAccumulator, response): any => ([
      ...dataAccumulator,
      ..._.get(response, ['data', '_embedded', 'tenants'], []),
    ]), []);
    // normalize the data
    const normalizedData = normalize(
      { tenants: nextData },
      { tenants: [tenantSchema] },
    );
    // return the normalized data
    return normalizedData;
  });

/**
 * Get a single tenant
 */
export const read = (tenantId: any): any => axios.get(
  routes.tenants.read(tenantId),
  getRequestOptions(),
).then((response): any => response.data);


export default {
  readAll,
  read,
};
