import React from 'react';
import PageHeader from '../components/PageHeader';
import NavigationHeader from '../components/NavigationHeader';
import MobileHeader from '../components/MobileHeader';
import Footer from '../components/Footer';

export interface MainWrapperProps{
  children: React.ReactNode | React.ReactNode[];
}

const MainWrapper = (props: MainWrapperProps): JSX.Element => (
  <div id="main-wrapper">
    <section className="container-flex container-vertical" id="main-body">
      <div id="wrap-content" className="container-flex container-vertical">
        <PageHeader />
        <NavigationHeader />
        <MobileHeader />
          {props.children}
      </div>
      <Footer />
    </section>
  </div>
);

export default MainWrapper;
