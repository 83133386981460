import React, { useEffect, useContext } from 'react';
import updateUrlParams from 'lib/updateUrlParams';
import ThemeContext from 'modules/Presales/context/ThemeContext';
import Carousel from '../components/Carousel';
import CentralButtons from '../components/CentralButtons';
import SignInInformation from '../components/SignInInformation';
import MainWrapper from '../wrappers/MainWrapper';

const SignIn = (): JSX.Element => {
  // Grab theme and update the url params to include ?[customer]
  const theme = useContext(ThemeContext);
  useEffect((): void => {
    updateUrlParams('signin', theme.param);
    window.scrollTo(0, 0);
  });
  return (
    <div id="signIn">
      <MainWrapper>
        <Carousel />
        <CentralButtons />
        <SignInInformation />
      </MainWrapper>
    </div>
  );
};

export default SignIn;
