import React from 'react';
import Mentor from 'assets/mentor.png';
import Energy from 'assets/energy.png';

const Information = (): JSX.Element => (
  <section id="information" className="container-flex">
  <div id="left-info" className="container-flex container-vertical info-section">
    <div id="top-left-info" className="container-flex container-vertical-center-items item-flex-1 left-info-section">
      <img src={Mentor} alt="mentor" className="item-flex-1" />
      <p className="item-flex-1">Our business mentoring program teaches small-business owners important skills and
        resources to help their companies succeed. Advisers share their experiences with an eye toward promoting
        economic health and stability.</p>
    </div>
    <div id="bottom-left-info"
      className="container-flex container-vertical-center-items item-flex-1 left-info-section">
      <img src={Energy} alt="wind mills" className="item-flex-1" />
      <p className="item-flex-1">Electric vehicle owners, and those considering the purchase of an electric vehicle,
        stand to benefit from APS&apos;s Energy Efficiency proposal currently before the Arizona Corporation
        Commission.
      </p>
    </div>
  </div>
  <div id="right-info" className="container-flex container-vertical container-center-align-items info-section">
    <p id="first-p">Take advantage of ABC Energy&apos;s online services that make life easier. It&apos;s time to experience
      the ease, convenience, and security of managing your account online.</p>
    <p id="second-p">Questions? Browse our <a href="#">Frequently Asked Questions,</a><br />call <a
        href="#">1.800.766.6616</a> or <a href="#">email your question.</a></p>
    <button id='right-info-button'>Find Out More</button>
  </div>
</section>
);

export default Information;
