import React, { useContext } from 'react';
import ThemeContext from 'modules/Presales/context/ThemeContext';

export interface Item{
  label: string;
  nav: string;
  disabled?: boolean;
}

export interface SubpageSidebarProps{
  navItems: Item[];
  onClick: any;
  activeItem: string;
}

export const SubpageSidebar = (props: SubpageSidebarProps): JSX.Element => {
  const { colorPrime } = useContext(ThemeContext);
  const activeBtn = (navItem: any) => props.activeItem === navItem.nav;
  return (
    <div id="subpage-sidebar" className="container-flex container-vertical">
      {props.navItems.map((navItem, index): JSX.Element => (
        <button
          type="button"
          tabIndex={0}
          onClick={(): void => props.onClick(navItem.nav)}
          key={index}
          disabled={navItem.disabled}
          className={`side-bar-link${activeBtn(navItem) ? ' active-nav' : ''}`}
          style={{ background: activeBtn(navItem) ? colorPrime : '' }}
        >
          {navItem.label}
        </button>
      ))}
    </div>
  );
};

export default SubpageSidebar;
