import _ from 'lodash';

/**
 * Given a request function, repeats request until all paged data has been obtained
 * @param {*} requestFn the function that makes the HTTP request
 * @param {*} options options passed to the request function
 */
const requestAllPagedData = (
  requestFn: any,
  options: any = {},
): any => (
  requestFn(options).then((response: any): any => {
    // get page data
    const totalPages = _.get(response, ['data', 'page', 'totalPages']);
    const pagesRemaining = (totalPages - 1);
    // init an array to hold remaining requests
    const batchedRequests = [];

    // for each page remaining, create a new request
    for (let nextPage = 1; nextPage <= pagesRemaining; nextPage += 1) {
      batchedRequests.push(requestFn({
        ...options,
        params: {
          ...options.params,
          page: nextPage,
        },
      }));
    }
    // return an array containing all responses
    // @ts-ignore
    return Promise.all(batchedRequests).then((nextResponses): [] => [response, ...nextResponses]);
  })
  // make the first request
);

export default requestAllPagedData;
