import Facebook from 'assets/facebook.svg';
import Twitter from 'assets/twitter.svg';
import Youtube from 'assets/youtube.svg';
import FacebookMobile from 'assets/facebook-mobile.svg';
import TwitterMobile from 'assets/twitter-mobile.svg';
import YoutubeMobile from 'assets/youtube-mobile.svg';
import routeConstants from 'config/routeConstants';


export const socialLinks = [
  {
    imgSrc: '',
    imgAlt: '',
  },
  {
    imgSrc: Facebook,
    imgAlt: 'facebook',
  },
  {
    imgSrc: Twitter,
    imgAlt: 'twitter',
  },
  {
    imgSrc: Youtube,
    imgAlt: 'youtube',
  },
];

export const socialLinksMobile = [
  {
    imgSrc: FacebookMobile,
    imgAlt: 'facebook',
  },
  {
    imgSrc: TwitterMobile,
    imgAlt: 'twitter',
  },
  {
    imgSrc: YoutubeMobile,
    imgAlt: 'youtube',
  },
];

export const navLinks = [
  {
    external: false,
    linkHref: '/homepage##account',
    linkLabel: 'Account Details',
  },
  {
    external: false,
    linkHref: '/homepage##outages',
    linkLabel: 'Outages',
  },
  {
    external: false,
    linkHref: routeConstants.AlertsManagement.fullRoute,
    linkLabel: 'Alert Preferences',
  },
  {
    external: false,
    linkHref: '/homepage#billing',
    linkLabel: 'Billing and Payments',
  },
  {
    external: false,
    linkHref: '/homepage##ai',
    linkLabel: 'Artificial Intelligence',
  },
];

export const pageLinks = [
  {
    external: false,
    linkHref: '#your-home',
    linkLabel: 'For Your Home',
    linkLocation: 1,
  },
  {
    external: false,
    linkHref: '#your-business',
    linkLabel: 'For Your Business',
    linkLocation: 1,
  },
  {
    external: false,
    linkHref: '#about',
    linkLabel: 'About Us',
    linkLocation: 1,
  },
  {
    external: true,
    linkHref: 'https://demo.kubra.io/admin/es/login',
    linkLabel: 'ES Admin',
    linkLocation: 2,
  },
  {
    external: false,
    linkHref: routeConstants.Tools.fullRoute,
    linkLabel: 'Administration',
    linkLocation: 2,
  },
  {
    external: false,
    linkHref: routeConstants.SignIn.fullRoute,
    linkLabel: 'Sign In',
    linkLocation: 2,
  },
];

export default {
  socialLinks,
  socialLinksMobile,
  navLinks,
  pageLinks,
};
