import React from 'react';
import {
  Route,
  Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import _ from 'lodash';
import routeConstants from 'config/routeConstants';
import ThemeContext, { themes } from 'modules/Presales/context/ThemeContext';
import { createBrowserHistory } from 'history';
import PrePay from './modules/Presales/pages/PrePay';
import Homepage from './modules/Presales/pages/Homepage';
import AlertsManagement from './modules/Presales/pages/AlertsManagement';
import Tools from './modules/Presales/pages/Tools';
import Login from './modules/Presales/pages/Login';
import SignIn from './modules/Presales/pages/SignIn';

const history = createBrowserHistory();

// Check session storage and url params for the customer names
const getTheme = (): string => {
  const themesArr = _.keys(themes);
  const locationSearch = _.trim(window.location.search, '?');
  const sessionStorageCustomer = window.sessionStorage.getItem('kubra-customer');
  let theme = 'abc';
  if (locationSearch && _.indexOf(themesArr, locationSearch) !== -1) {
    window.sessionStorage.setItem('kubra-customer', locationSearch);
    theme = locationSearch;
  } else if (!locationSearch && sessionStorageCustomer !== null) {
    theme = sessionStorageCustomer;
  }
  return theme;
};

export const App = (): JSX.Element => (
  <div id="app">
    <ThemeContext.Provider value={themes[getTheme()]}>
      <Router history={history}>
        <Switch>
          <Route
            path={routeConstants.Homepage.fullRoute}
            component={Homepage}
          />
          <Route
            path={routeConstants.AlertsManagement.fullRoute}
            component={AlertsManagement}
          />
          <Route
            path={routeConstants.PrePay.fullRoute}
            component={PrePay}
          />
          <Route
            path={routeConstants.Tools.fullRoute}
            component={Tools}
          />
          <Route
            path={routeConstants.Login.fullRoute}
            component={Login}
          />
          <Route
            path={routeConstants.SignIn.fullRoute}
            component={SignIn}
          />
          <Redirect to={routeConstants.Homepage.fullRoute} />
        </Switch>
      </Router>
    </ThemeContext.Provider>
  </div>
);

export default App;
