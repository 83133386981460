import {
  schema,
} from 'normalizr';

// tenant schema
export const tenantSchema = new schema.Entity('tenants');

export default {
  tenantSchema,
};
