import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import ThemeContext from 'modules/Presales/context/ThemeContext';

interface ListItem {
  name: string;
  value: string;
}

interface DropDownDefaultProps {
  onChange?: Dispatch<SetStateAction<string>>;
}

interface DropDownProps extends DropDownDefaultProps{
  listItems: ListItem[];
  defaultItem: ListItem;
}

export const DropDown = (props: DropDownProps): JSX.Element => {
  const { listItems, defaultItem, onChange } = props;
  const [isOpen, setIsopen] = useState(false);
  const [activeItem, setActiveItem] = useState(defaultItem || '');
  const containerRef = useRef<HTMLDivElement>(null);
  const { colorPrime } = useContext(ThemeContext);
  const handleClick = (event: any): any => {
    if (containerRef.current && !containerRef.current.contains(event.target)) setIsopen(false);
  };

  useEffect((): any => {
    document.addEventListener('mousedown', handleClick);
    return (): void => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const itemClick = (item: ListItem): void => {
    setActiveItem(item);
    setIsopen(false);
    if (onChange) onChange(item.value);
  };

  return (
    <div
      ref={containerRef}
      className="dropdown-container"
      onClick={(): void => setIsopen(!isOpen)}
      tabIndex={1}
      style={{ backgroundColor: colorPrime }}
    >
      <div className={`arrow-container${isOpen ? ' up' : ' down'}`} />
      {activeItem.name}
      <ul className={`drop-down-list${!isOpen ? ' hidden' : ''}`} style={{ background: 'white' }}>
        {listItems.map(
          (item: ListItem, index: number): JSX.Element => (
            <li key={index} onClick={(): void => itemClick(item)} value={item.value}>
              {item.name}
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

export default DropDown;
