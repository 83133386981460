// import { supportsGoWithoutReloadUsingHash } from 'history/DOMUtils';
import { api } from '../../../config';

interface IGetRoutesTenants {
  readAll: () => string;
  read: (tenantId: string) => string;
}
interface IGetRoutes {
  tenants: IGetRoutesTenants;
}

const getRoutes = (host: string): IGetRoutes => ({
  tenants: {
    readAll: (): string => `${host}/tenants`,
    read: (tenantId: string): string => `${host}/tenants/${tenantId}`,
  },
});

const routes = getRoutes(api.iamApi.host);

export default routes;
