import React, { useContext } from 'react';
import Computer from 'assets/computer.svg';
import Bolt from 'assets/bolt.svg';
import ThemeContext from 'modules/Presales/context/ThemeContext';
import { NavLink } from 'react-router-dom';
import routeConstants from 'config/routeConstants';

const CentralButtons = (): JSX.Element => {
  const { colorPrime, colorSecond } = useContext(ThemeContext);
  return (
    <section className="container-flex" id="central-buttons">
        <div role="button" tabIndex={1} id="prepay-button" style={{ background: colorPrime }}
          className="container-flex container-vertical-center-items item-flex-1">
            <div className="item-flex-1 central-buttons-img">
              <NavLink to={routeConstants.PrePay.fullRoute}>
              <img src={Computer} alt="computer" />
              </NavLink>
            </div>
            <div className="item-flex-3 central-buttons-text">
            <NavLink to={routeConstants.PrePay.fullRoute}>
              <h4>Introducing ABC PRE-PAY!</h4>
              <p>Learn more about how you can sign up and pay for energy as you use it.</p>
            </NavLink>
            </div>
        </div>
      <div role="button" tabIndex={1} id="outage-button" style={{ background: colorSecond }}
        className="container-flex container-vertical-center-items item-flex-1">
        <div className="item-flex-1 central-buttons-img">
          <img src={Bolt} alt="computer" className="item-flex-1" />
        </div>
        <div className="item-flex-3 central-buttons-text">
          <h4>Report an Outage</h4>
          <p>Report and receive updates about electric outages and streetlight outages.</p>
        </div>
      </div>
    </section>
  );
};

export default CentralButtons;
