import React from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import routeConstants from 'config/routeConstants';
import ThemeContext from '../context/ThemeContext';

const linkData = [
  {
    header: 'Account Details',
    links: [
      { label: 'Manage Account', external: false, mobile: false },
      { label: 'Start/Stop Service', external: false, mobile: false },
      {
        label: 'Request a Demo',
        href: 'https://kubra.com/request-a-demo/',
        external: true,
        mobile: false,
      },
      { label: 'FAQs', external: false, mobile: false },
    ],
  },
  {
    header: 'Outages',
    links: [
      { label: 'Report Electrical Outage', external: false, mobile: false },
      { label: 'Report Streetlight Outage', external: false, mobile: false },
      { label: 'View Reliability Map', external: false, mobile: false },
      { label: 'IncidentWatch', external: false, mobile: false },
    ],
  },
  {
    header: 'Alerts Management',
    links: [
      {
        label: 'Alert Preferences',
        href: routeConstants.AlertsManagement.fullRoute,
        external: false,
        mobile: true,
      },
    ],
  },
  {
    header: 'Billing and Payments',
    links: [
      { label: 'Pay Bill Online', external: false, mobile: false },
      { label: 'Automatic Payments', external: false, mobile: false },
    ],
  },
  {
    header: 'Artificial Intelligence',
    links: [
      { label: 'Social Media Messaging', external: false, mobile: false },
      { label: 'Web Chat Bot', external: false, mobile: false },
    ],
  },
  {
    header: 'Updates for Local Officials',
    links: [
      { label: 'Municipal Portal', external: false, mobile: false },
      { label: 'Infrastructure Reports', external: false, mobile: false },
    ],
  },
];

const getFooterLinks = (data: any, mobileVersion: boolean): any => data.links.map(
  (link: any, i: string): JSX.Element => {
    const href = link.href ? link.href : `#${_.kebabCase(link.label)}`;
    const { external, mobile } = link;

    let renderLink: any;

    if (mobileVersion && !mobile) renderLink = <div key={i} />;
    else if (external) {
      renderLink = <a className="footer-link" href={href} key={i} target="_blank" rel="noopener noreferrer">{link.label}</a>;
    } else {
      renderLink = <NavLink className="footer-link" key={i} to={href}>{link.label}</NavLink>;
    }
    return (
      renderLink
    );
  },
);

const dt = new Date();

const Footer = (): JSX.Element => (
  <footer id="app-footer" className="container-flex container-vertical">
    <section id="footer-links"
      className="container-flex container-center-align-items container-vertical-center-baseline-items">
      {linkData.map((data, index): JSX.Element => (
        <div className="container-flex container-vertical item-flex-1" key={index}>
        <h6 className="footer-link">{data.header}</h6>
        {getFooterLinks(data, false)}
      </div>
      ))}
    </section>
    <section id="footer-mobile-links">
      {linkData.map((data): any => getFooterLinks(data, true))}
    </section>
    <section id="copyright">
      <ThemeContext.Consumer>
            {(): JSX.Element => (
              <p>&copy; { dt.getFullYear() } KUBRA - Proprietary &amp; Confidential</p>
            )}
      </ThemeContext.Consumer>
    </section>
  </footer>
);

export default Footer;
