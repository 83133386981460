import React from 'react';
import { NavLink } from 'react-router-dom';
import { navLinks } from 'constants/headerLinks';
import routeConstants from 'config/routeConstants';
import ThemeContext from '../context/ThemeContext';

const NavigationHeader = (): JSX.Element => (
  <header className="container-flex" id="navigation-header">
    <div className="container-flex container-vertical-center-items item-flex-1">
      <ThemeContext.Consumer>
        {(value): JSX.Element => (
          <NavLink to={routeConstants.Homepage.fullRoute}>
            <img src={value.logo} alt="logo" id="navigation-header-logo" />
          </NavLink>
        )}
      </ThemeContext.Consumer>
    </div>
    <div className="container-flex container-vertical-center-items container-right-align-items container-space-evenly-items item-flex-1">
      {navLinks.map((navLink, index): JSX.Element => (
        <div className="navigation-header-link" key={index}>
          {navLink.external && (
            <a href={navLink.linkHref} key={index} target="_blank" rel="noopener noreferrer">
              {navLink.linkLabel}
            </a>
          )}
          {!navLink.external && (
            <NavLink to={navLink.linkHref}>
              {navLink.linkLabel}
            </NavLink>
          )}
        </div>
      ))}
    </div>
  </header>
);

export default NavigationHeader;
