import React, { useState } from 'react';
import WomanPhone from 'assets/woman-phone.png';
// import ManPhone from 'assets/man-phone.png';

const carouselImages = [
  {
    buttonIndex: 1,
    imgSrc: WomanPhone,
    imgAlt: 'businesswoman',
  },
];

const Carousel = (): JSX.Element => {
  const [activeBtn] = useState(carouselImages[0]);
  return (
    <section id="carousel">
      <img id="carousel-img" src={activeBtn.imgSrc} alt={activeBtn.imgAlt} />
      {/* <div id="carousel-radio-buttons" className="container-flex container-space-between-items">
        killing carousel functionality for the time being per design
        {carouselImages.map((img): JSX.Element => (
          <div
            key={img.buttonIndex}
            id={`radio-button-${img.buttonIndex}`}
            className={`radio-button${activeBtn.buttonIndex === img.buttonIndex ? ' active-radio' : ''}`}
            role="button"
            tabIndex={1}
            onClick={(): void => setActiveBtn(img)}
          />
        ))}
      </div>  */}
    </section>
  );
};

export default Carousel;
