import React, { useContext } from 'react';
import ThemeContext from 'modules/Presales/context/ThemeContext';

export interface SubpageBannerProps{
  header: string;
  subtext: string;
}

const SubpageBanner = (props: SubpageBannerProps): JSX.Element => {
  const { colorSecond } = useContext(ThemeContext);
  return (
    <div id="subpage-banner" style={{ background: colorSecond }} className="container-flex container-vertical container-center-align-items">
      <h1>{props.header}</h1>
      <h6>{props.subtext}</h6>
    </div>
  );
};

export default SubpageBanner;
