import { combineReducers } from 'redux';
import entities from './entities';

const rootReducers = {
  entities,
};

/**
 * A function that will use combineReducers to merge additional reducers with the root reducer
 * @param {*} additionalReducers
 */

export const getRootReducerWith = (additionalReducers = {}): any => combineReducers({
  ...rootReducers,
  ...additionalReducers,
});

export default combineReducers(rootReducers);
