import _ from 'lodash';
// @ts-ignore
import { getAuthItem } from 'kubra-lib-react-auth/dist';

/**
 * Get an object containing the options that are common across all IAM API requests
 * @param {*} options
 */
interface IOptions {
  headers?: object;
}

const getRequestOptions = (options?: IOptions): object => {
  const token = getAuthItem('accessToken');
  const commonOptions = {
    headers: {
      // add bearer token to headers
      Authorization: `Bearer ${token}`,
      // spread headers if exists on input options
      ..._.get(options, 'headers', {}),
    },
  };

  return {
    ...commonOptions,
    ...options,
  };
};

export default getRequestOptions;
